@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.30fa2792.woff2") format("woff2"), url("Inter-Thin.509b3195.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.baaa147a.woff2") format("woff2"), url("Inter-ThinItalic.305c6c76.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.964a821e.woff2") format("woff2"), url("Inter-ExtraLight.9bd9159b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.8892db5f.woff2") format("woff2"), url("Inter-ExtraLightItalic.a68f8177.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.c06f5e9d.woff2") format("woff2"), url("Inter-Light.5feaf686.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.9361988e.woff2") format("woff2"), url("Inter-LightItalic.59e166b6.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.449f0e8a.woff2") format("woff2"), url("Inter-Regular.e79139c9.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.26f3dc3f.woff2") format("woff2"), url("Inter-Italic.9092ffa2.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.acc3d50f.woff2") format("woff2"), url("Inter-Medium.cfcc1d28.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.078088d6.woff2") format("woff2"), url("Inter-MediumItalic.4aa3953c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.bdb1c7ad.woff2") format("woff2"), url("Inter-SemiBold.fa7f9547.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.2550cf4d.woff2") format("woff2"), url("Inter-SemiBoldItalic.fb0578b1.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.53ea85ae.woff2") format("woff2"), url("Inter-Bold.d03e3d36.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.2c92d711.woff2") format("woff2"), url("Inter-BoldItalic.4ca4d3bd.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.3eeb4074.woff2") format("woff2"), url("Inter-ExtraBold.0d648a7c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.1abc120e.woff2") format("woff2"), url("Inter-ExtraBoldItalic.6beaf16d.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.1da350e0.woff2") format("woff2"), url("Inter-Black.0258c5ed.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.f3c01519.woff2") format("woff2"), url("Inter-BlackItalic.2de967a4.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-roman.var.b695afbe.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.69eb0fe1.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("Inter.var.5f2f8893.woff2") format("woff2");
}

/*# sourceMappingURL=index.13c88116.css.map */
